import BaseService from "../../services/base_service"

const requestMaterialsService = new BaseService(
  process.env.VUE_APP_MS_REQUESTS_MATERIALS_URL,
  process.env.VUE_APP_MS_REQUESTS_MATERIALS_API_KEY
)

const deliveryRequestsService = new BaseService(
  process.env.VUE_APP_MS_DELIVERY_REQUESTS_URL,
  process.env.VUE_APP_MS_DELIVERY_REQUESTS_API_KEY
)

export const state = {
  loading: false,
  allGroupArticles: [],
  allFamilies: [],
  allExpenseItems: [],
  allUnits: [],
  allDestinyOptions: [],
  formMaterials: {
    total: 0,
    number_of_pages: 1,
    items: []
  },
  allSupplierDrafts: [],
  allTypesDocumentPayment: [],
  allPaymentMethods: [],
  allPaymentConditions: [],
  allBanks: [],
  suppliers: {
    total: 0,
    number_of_pages: 1,
    items: []
  },
  reportProducts: {
    total_items: 0,
    total_pages: 1,
    material_report: []
  },
  reportStock: {
    total_items: 0,
    total_pages: 1,
    stock_report: []
  },
  requestsDelivery: {
    total: 0,
    number_of_pages: 0,
    items: []
  },
  purchaseRequests: {
    total: 0,
    number_of_pages: 0,
    items: []
  }
}

export const getters = {
  loading: (state) => state.loading,
  allGroupArticles: (state) => state.allGroupArticles,
  allDestinyOptions: (state) => state.allDestinyOptions,
  allExpenseItems: (state) => state.allExpenseItems,
  allUnits: (state) => state.allUnits,
  allFamilies: (state) => state.allFamilies,
  reportProducts: (state) => state.reportProducts,
  reportStock: (state) => state.reportStock,
  formMaterials: (state) => state.formMaterials,
  allSupplierDrafts: (state) => state.allSupplierDrafts,
  allPaymentConditions: (state) => state.allPaymentConditions,
  allPaymentMethods: (state) => state.allPaymentMethods,
  allTypesDocumentPayment: (state) => state.allTypesDocumentPayment,
  allBanks: (state) => state.allBanks,
  suppliers: (state) => state.suppliers,
  requestsDelivery: (state) => state.requestsDelivery,  
  purchaseRequests: (state) => state.purchaseRequests,  
}

export const mutations = {
  SET_LOADING(state, payload) {
    state.loading = payload
  },
  SET_GROUP_ARTICLES(state, payload){
    state.allGroupArticles = payload
  },
  SET_UNITS(state, payload){
    state.allUnits = payload 
  },
  SET_FAMILIES(state, payload){
    state.allFamilies = payload
  },
  SET_EXPENSE_ITEMS(state, payload){
    state.allExpenseItems = payload
  },
  SET_DESTINY_OPTIONS(state, payload){
    state.allDestinyOptions = payload
  },
  SET_FORM_MATERIALS(state, payload) {
    state.formMaterials = payload
  },
  SET_SUPPLIER_DRAFTS(state, payload){
    state.allSupplierDrafts = payload
  },
  SET_PAYMENT_CONDITIONS(state, payload){
    state.allPaymentConditions = payload
  },
  SET_PAYMENT_METHODS(state, payload){
    state.allPaymentMethods = payload
  },
  SET_TYPES_DOCUMENT_PAYMENT(state, payload){
    state.allTypesDocumentPayment = payload 
  },
  SET_BANKS(state, payload){
    state.allBanks = payload
  },
  SET_SUPPLIERS(state, payload){
    state.suppliers = payload
  },
  SPLICE_FILE_SUPPLIER_DELETED(state, payload){
    let supplierIndex = state.suppliers.items.findIndex(item => item.id === payload.supplier_id)
    state.suppliers.items[supplierIndex].documents = state.suppliers.items[supplierIndex].documents.filter(item => {
      return item.id !== payload.items[0].id
    }) 
  },
  SET_REPORT_PRODUCTS(state, payload) {
    state.reportProducts = payload
  },
  SET_REPORT_STOCK(state, payload) {
    state.reportStock = payload
  },
  CLEAR_FORM_MATERIALS(state){
    state.formMaterials = {
      total: 0,
      number_of_pages: 1,
      items: []
    }
  },
  CLEAR_SUPPLIERS(state) {
    state.suppliers = {
      total: 0,
      number_of_pages: 1,
      items: []
    }
  },
  CLEAR_DELIVERY_REQUESTS(state) {
    state.requestsDelivery = {
      total: 0,
      number_of_pages: 0,
      items: []
    }
  },
  SET_FORM_MATERIALS(state, payload) {
    state.formMaterials = payload
  },
  SET_REQUESTS_DELIVERY(state, payload){
    if(payload?.filters?.grouping_filter){
      state.requestsDelivery = payload.data
      state.requestsDelivery.items = state.requestsDelivery.items.map(item => {
        return {
          ...item,
          id: Object.keys(item)[0],
        }
      })  
    }else {
      state.requestsDelivery = payload
    }
  },
  SET_PURCHASE_REQUESTS(state, payload){
    state.purchaseRequests = payload
  },
  UPDATE_ETA_MATERIALS_PURCHASE_REQUEST(state, payload){
    let currentPurchaseRequestKey = state.purchaseRequests.items.findIndex(item => item.id == payload.id)
    state.purchaseRequests.items[currentPurchaseRequestKey].items.forEach(material => {
      if(payload.materials.find(item => item.id == material.id)){
        material.delivery_date = payload.delivery_date,
        material.user_delivery_date = payload.user_delivery_date
      }
    })
  },
  UPDATE_STATUS_MATERIAL_PURCHASE_REQUEST(state, payload){
    let currentPurchaseRequestKey = state.purchaseRequests.items.findIndex(item => item.id == payload.request_id)
    state.purchaseRequests.items[currentPurchaseRequestKey].items.forEach(material => {
      if(payload.items[0].id == material.id){
        material.status = payload.status_text
        material.status_manual = payload.status_text
      }
    })
  },
  UPDATE_STATUS_PURCHASE_REQUEST(state, payload){
    let currentPurchaseRequestKey = state.purchaseRequests.items.findIndex(item => item.id == payload.id)
    state.purchaseRequests.items[currentPurchaseRequestKey].status = payload.status_text
    state.purchaseRequests.items[currentPurchaseRequestKey].status_manual = payload.status_text
    state.purchaseRequests.items[currentPurchaseRequestKey].items.forEach(material => {
      material.status = payload.status_text
      material.status_manual = payload.status_text
    })
  },
  UPDATE_STATUS_TRANSFER_REQUEST(state, payload){
    let currentTransferRequestKey = state.requestsDelivery.items.findIndex(item => item.id == payload.id_group)
    state.requestsDelivery.items[currentTransferRequestKey][state.requestsDelivery.items[currentTransferRequestKey].id].forEach(item => {
      if(payload.id == item.id)
      item.manual_status = payload.status
    })

  }  
}

export const actions = {
  async verifyUserBelongsToSupplying({_}, payload){
    try {
      const resp = (await deliveryRequestsService.get(
        `/api/material_creation/is_abastecimiento/${payload.email}`)).data
      return resp
    } catch (error) {
      return error
    }
  },
  async getListOptionsSupplying({ commit }) {
    try {
      const resp = (await requestMaterialsService.get(
        `/api/reports/get_dropdowns`)).data
      commit('SET_GROUP_ARTICLES', resp.grupo_articulos)
      commit('SET_UNITS', resp.unidad_medida)
      commit('SET_EXPENSE_ITEMS', resp.item_gastos)
      commit('SET_FAMILIES', resp.familias)
      commit('SET_DESTINY_OPTIONS', resp.proyecto_ceco)
      return resp
    } catch (error) {
      return error
    }
  },
  async getListOptionsSupplier({ commit }) {
    try {
      const resp = (await deliveryRequestsService.get(
        `/api/supplier_creation/dropdowns`)).data
      commit('SET_SUPPLIER_DRAFTS', resp.business_category)
      commit('SET_BANKS', resp.banks)
      commit('SET_TYPES_DOCUMENT_PAYMENT', resp.boleta_factura)
      commit('SET_PAYMENT_METHODS', resp.payment_method)
      commit('SET_PAYMENT_CONDITIONS', resp.payment_condition)
      return resp
    } catch (error) {
      return error
    }
  },
  async getFormMaterials({ commit }, payload = {}) {
    try {
      const resp = (await deliveryRequestsService.get(
        `/api/material_creation/all_materials`, payload)).data
      commit('SET_FORM_MATERIALS', resp)
      return resp
    } catch (error) {
      return error
    }
  },
  clearFormMaterials({commit}){
    commit('CLEAR_FORM_MATERIALS')
  },
  async updateOrCreateFormMaterial({commit}, payload){
    let resp = null
    let payloadCreate = new FormData()
    payloadCreate.append('body', JSON.stringify({...payload, files: undefined}))
    if(payload.files?.length){
      for(let i = 0; i < payload.files.length; i++){
        payloadCreate.append(`file`, payload.files[i])
      }
    }
    try {
       if(payload.id){
        resp = (await deliveryRequestsService.put(
          `/api/material_creation/update_material`, payload))
  
       }else {
        if(payload.completed){
          resp = (await deliveryRequestsService.post(
            `/api/material_creation/create_material_abastecimiento`, payloadCreate))
        }else{
          resp = (await deliveryRequestsService.post(
           `/api/material_creation/create_material`, payloadCreate))
        }
       }
      return resp
    } catch (error) {
      return error.response
    }
  },
  async updateStatusFormMaterial({ commit }, payload) {
    try {
      const resp = await deliveryRequestsService.put(
        `/api/material_creation/approve_material`, payload)
        return resp
      } catch (error) {
        return error.response
      }
  },
  async updateStatusReviewForm({commit}, payload){
    try {
      const resp = await deliveryRequestsService.post(
        `/api/comment/post_comment/?request_id=${payload.request_id}&warning=${payload.warning}&rejected=${payload.rejected}&resolved=${payload.resolved}`, payload)
        return resp
      } catch (error) {
        return error.response
      }
  },
  async rejectForm({commit}, payload){
    try {
      const resp = await deliveryRequestsService.post(
        `/api/comment/post_comment/?request_id=${payload.request_id}&rejected=true`, payload)
        return resp
      } catch (error) {
        return error.response
      }
  },
  async createCommentForm({commit}, payload){
    try {
      const resp = await deliveryRequestsService.post(
        `/api/comment/post_comment/?request_id=${payload.request_id}`, payload)
        return resp
      } catch (error) {
        return error.response
      }
  },
  async deleteCommentForm({commit}, payload){
    try {
      const resp = await deliveryRequestsService.delete(
        `/api/comment/delete_comment/?comment_id=${payload.comment_id}&user=${payload.user}`, payload)
        return resp
      } catch (error) {
        return error.response
      }
  },
  async downloadExcelFormMaterials({commit}, payload){
      let url = `/api/material_creation/export_excel` 
      try {
        const resp = (
          await deliveryRequestsService.get(url, payload, 'blob')
        ).data
        return resp
      } catch (error) {
        console.log(error)
        return error
      }
  },
  async getSuppliers({ commit }, payload = {}) {
    try {
      const resp = (await deliveryRequestsService.get(
        `/api/supplier_creation/all_suppliers`, payload)).data
      commit('SET_SUPPLIERS', resp)
      return resp
    } catch (error) {
      return error
    }
  },
  async updateOrCreateSupplier({commit, dispatch}, payload){
    let resp = null
    let payloadCreate = new FormData()
    payloadCreate.append('body', JSON.stringify({...payload, files: undefined}))
    if(payload.files?.length){
      for(let i = 0; i < payload.files.length; i++){
        payloadCreate.append(`files`, payload.files[i])
      }
    }
    try {
       if(payload.id){
        resp = (await deliveryRequestsService.put(
          `/api/supplier_creation/update_supplier`, payload))
        
        if(payload.completed && payload.files?.length){
          await dispatch('updateFilesSupplier', payload)
        }
       }else {
        if(payload.completed){
          resp = (await deliveryRequestsService.post(
            `/api/supplier_creation/create_supplier_abastecimiento`, payloadCreate))
        }else{
          resp = (await deliveryRequestsService.post(
           `/api/supplier_creation/create_supplier`, payload))
        }
       }
      return resp
    } catch (error) {
      console.log(error)
      return error.response
    }
  },
  async updateFilesSupplier({commit}, payload){
    try {        
      let payloadUpdateFiles = new FormData()
      payloadUpdateFiles.append('body', JSON.stringify({
        id: payload.id,
        user: payload.user
      }))
      for(let i = 0; i < payload.files.length; i++){
        payloadUpdateFiles.append(`files`, payload.files[i])
      }
      const resp = await deliveryRequestsService.put(
        `api/supplier_creation/update_supplier_document`,
        payloadUpdateFiles
      )
      return resp
    } catch (error) {
      return error.response
    }
  },
  async updateStatusSupplier({ commit }, payload) {
    try {
      const resp = await deliveryRequestsService.put(
        `/api/supplier_creation/approve_supplier`, payload)
        return resp
      } catch (error) {
        return error.response
      }
  },
  async deleteFileSupplier({ commit }, payload) {
    try {
      const resp = await deliveryRequestsService.delete(
        `/api/supplier_creation/delete_supplier_document`, payload)
      commit('SPLICE_FILE_SUPPLIER_DELETED', payload)
      return resp
    } catch (error) {
      console.log(error)
      return error.response
    }
  },
  async downloadExcelSupplier({commit}, payload){
      let url = `/api/supplier_creation/export_excel` 
      try {
        const resp = (
          await deliveryRequestsService.get(url, payload, 'blob')
        ).data
        return resp
      } catch (error) {
        return error
      }
  },
  async getReportProducts({ commit }, payload = {}) {
    commit('SET_LOADING', true)
    try {
      const resp = (await requestMaterialsService.get(
        `/api/reports/material_report`, payload)).data
      commit('SET_REPORT_PRODUCTS', resp)
      return resp
    } catch (error) {
      return error
    } finally {
      commit('SET_LOADING', false)
    }
  },
  async updateReportProducts({ commit }) {
    commit('SET_LOADING', true)
    try {
      const resp = await requestMaterialsService.put(
        `/api/reports/update_material_report`)
        return resp
    } catch (error) {
      return error
    } finally {
      commit('SET_LOADING', false)
    }
  },
  async getReportStock({ commit }, payload = {}) {
    commit('SET_LOADING', true)
    try {
      const resp = (await requestMaterialsService.get(
        `/api/reports/stock_report`, payload)).data
      commit('SET_REPORT_STOCK', resp)
      return resp
    } catch (error) {
      return error
    } finally {
      commit('SET_LOADING', false)
    }
  },
  async updateReportStock({ commit }) {
    commit('SET_LOADING', true)
    try {
      const resp = await requestMaterialsService.put(
        `/api/reports/update_stock_report`)
      return resp
    } catch (error) {
      return error
    } finally {
      commit('SET_LOADING', false)
    }
  },
  async downloadExcelReportProducts({ commit }, payload) {
    let url = `/api/reports/material_report/export` 
    try {
      const resp = (
        await requestMaterialsService.get(url, payload, 'blob')
      ).data
      return resp
    } catch (error) {
      return error
    }
  },
  async downloadExcelReportStock({ commit }, payload) {
    let url = `/api/reports/stock_report/export` 
    try {
      const resp = (
        await requestMaterialsService.get(url, payload, 'blob')
      ).data
      return resp
    } catch (error) {
      return error
    }
  },
  clearSuppliers({commit}){
   commit('CLEAR_SUPPLIERS') 
  },
  clearDeliveryRequests({commit}){
    commit('CLEAR_DELIVERY_REQUESTS')
  },
  async getRequestsDelivery({ commit }, payload = {}) {
    try {
      const resp = (await deliveryRequestsService.get(
        `/api/delivery/material_delivery`, payload)).data
      if(payload?.grouping_filter){
        commit('SET_REQUESTS_DELIVERY', {data: resp, filters: payload})
      }else{
        commit('SET_REQUESTS_DELIVERY', resp)
      }
      return resp
    } catch (error) {
      console.log(error)
      return error
    }
  },
  async dispatchRequestsDelivery({ commit}, payload) {
    try {
      const resp = await deliveryRequestsService.put(
        `/api/delivery/material_delivery`, payload)
      return resp
    } catch (error) {
      return error.response
    }
  },
  async getPurchaseRequests({ commit }, payload = {}) {
    try {
      const resp = (await deliveryRequestsService.get(
        `/api/purchase/material_purchase_request`, payload)).data
      commit('SET_PURCHASE_REQUESTS', resp)
      return resp
    } catch (error) {
      return error
    }
  },
  async updateEtaMaterialsRequest({ commit}, payload) {
    try {
      const resp = await deliveryRequestsService.put(
        `/api/purchase/item_estimated_delivery_date`, payload)
        return resp
      } catch (error) {
        return error.response
      }
  },
  async dispatchMaterialsDelivery({ commit}, payload) {
    try {
      const resp = await deliveryRequestsService.put(
        `/api/delivery/material_delivery_items`, payload)
      return resp
    } catch (error) {
      return error.response
    }
  },
  async updateStatusPurchaseRequest({ commit}, payload) {
    try {
      const resp = await deliveryRequestsService.put(
        `/api/purchase/update_status_purchase_request`, payload)
      commit('UPDATE_STATUS_PURCHASE_REQUEST', payload)
      return resp
    } catch (error) {
      return error.response
    }
  },
  async updateStatusTransferRequest({commit}, payload){
    try {
      const resp = await deliveryRequestsService.put(
        `/api/delivery/material_delivery_status/`, payload)
      commit('UPDATE_STATUS_TRANSFER_REQUEST', payload)
      return resp
    } catch (error) {
      console.log(error)
      return error.response
    }
  },
  async updateStatusMaterialPurchaseRequest({ commit}, payload) {
    try {
      const resp = await deliveryRequestsService.put(
        `/api/purchase/update_status_purchase_request_item`, payload)
      commit('UPDATE_STATUS_MATERIAL_PURCHASE_REQUEST', payload)
      return resp
    } catch (error) {
      return error.response
    }
  },
  updatEtaCurrentPurchaseRequest({commit}, payload){
    commit('UPDATE_ETA_MATERIALS_PURCHASE_REQUEST', payload)
  }
}
