import ApiMsProjectsService from '@/services/ms_projects_service'
export const state = {
  loading: false,
  loadingChunked: false,
  percentageUploadedChunked: null,
}

export const getters = {
  loading: (state) => state.loading,
  loadingChunked: (state) => state.loadingChunked,
  percentageUploadedChunked: (state) => state.percentageUploadedChunked
}

export const mutations = {
  SET_LOADING(state, payload) {
    state.loading = payload
  },
  SET_LOADING_CHUNKED(state, payload){
    state.loadingChunked = payload
  },
  SET_PERCENTAGE_UPLOADED_CHUNKED(state, payload){
    state.percentageUploadedChunked = payload
  }
}

export const actions = {
  async uploadDocumentFromPlace({ commit }, payload) {
    commit('SET_LOADING', true)
    try {
      const resp = await ApiMsProjectsService.post(`/upload_document`, payload)
      return resp
    } catch (error) {
      return error
    } finally {
      commit('SET_LOADING', false)
    }
  },
  async uploadDocumentChunckedFromPlace({ commit }, payload) {
    commit('SET_LOADING_CHUNKED', true)
    let {file, ...rest} = payload
    let resp = null
    let first_upload = true
    let final_upload = false
    const chunkSize = 50 * 1024 * 1024;
    const filename = file.name;
    for (let start = 0; start < file.size; start += chunkSize) {
      const end = Math.min(start + chunkSize, file.size);
      const chunk = file.slice(start, end);
      let formData = new FormData()
      formData.append("body", JSON.stringify(rest));
      formData.append("file", chunk, filename)
      if(start > 0){
        first_upload = false
      }
       if (end === file.size) {
        final_upload = true
      }
      try {
        resp = await ApiMsProjectsService.post(
          `/upload_file_chunked_v2?first_upload=${first_upload}&final_upload=${final_upload}`,
           formData
        )
      } catch (error) {
        console.log(error)
        return error
      } finally {
      }
      const percent = (end / file.size) * 100;
      commit('SET_PERCENTAGE_UPLOADED_CHUNKED', percent);
    }
    commit('SET_LOADING_CHUNKED', false)
    commit('SET_PERCENTAGE_UPLOADED_CHUNKED', null);
    return resp
  }

}
