import ApiMsApproveRequestMaterialsService from '@/services/ms_approve_request_materials'
import BaseService from '@/services/base_service'

const deliveryRequestsService = new BaseService(
  process.env.VUE_APP_MS_DELIVERY_REQUESTS_URL,
  process.env.VUE_APP_MS_DELIVERY_REQUESTS_API_KEY
)

export const state = {
  loading: false,
  approvalsMatrix: [],
  employees: {
    count: 0,
    employees: [],
    pages: 23
  },
  substituteEmployees: {
    count: 0,
    employees: [],
    pages: 23
  },
  positions: [],
  submanagments: []
}

export const getters = {
  loading: (state) => state.loading,
  approvalsMatrix: (state) => state.approvalsMatrix,
  employees: (state) => state.employees,
  substituteEmployees: (state) => state.substituteEmployees,
  positions: (state) => state.positions,
  submanagments: (state) => state.submanagments
}

export const mutations = {
  SET_LOADING(state, payload) {
    state.loading = payload
  },
  SET_APPROVALS_MATRIX(state, payload) {
    state.approvalsMatrix = payload
  },
  SET_EMPLOYEES(state, payload){
    state.employees = payload
  },
  SET_SUBMANAGMENTS(state, payload){
    state.submanagments = payload
  },
  SET_POSITIONS(state, payload){
    state.positions = payload
  },
  SET_SUBSTITUTE_EMPLOYEES(state, payload){
    state.substituteEmployees = payload
  }
}
export const actions = {
  async getApprovalsMatrix({ commit }, payload = {}) {
    try {
      const resp = (
        await ApiMsApproveRequestMaterialsService.get(`/api/mantainers/approval_matrix`, payload)
      ).data
      commit('SET_APPROVALS_MATRIX', resp)
      return resp
    } catch (error) {
      return error
    }
  },
  async getEmployees({ commit }, payload = {}) {
    try {
      const resp = (
        await ApiMsApproveRequestMaterialsService.get(`/api/mantainers/employees`, payload)
      ).data
      commit('SET_EMPLOYEES', resp)
      return resp
    } catch (error) {
      return error
    }
  },
  async getSubmanagments({ commit }, payload = {}) {
    try {
      const resp = (
        await ApiMsApproveRequestMaterialsService.get(`/api/mantainers/submanagement`, payload)
      ).data
      commit('SET_SUBMANAGMENTS', resp)
      return resp
    } catch (error) {
      return error
    }
  },
  async getPositions({ commit }, payload = {}) {
    try {
      const resp = (
        await ApiMsApproveRequestMaterialsService.get(`/api/mantainers/position`, payload)
      ).data
      commit('SET_POSITIONS', resp)
      return resp
    } catch (error) {
      return error
    }
  },
  async getSubstituteEmployees({ commit }, payload = {}) {
    try {
      const resp = (
        await ApiMsApproveRequestMaterialsService.get(`/api/mantainers/employees`, payload)
      ).data
      commit('SET_SUBSTITUTE_EMPLOYEES', resp)
      return resp
    } catch (error) {
      return error
    }
  },
  async updateApprovalMatrix({ commit }, payload) {
    commit('SET_LOADING', true)
    try {
      const resp = await ApiMsApproveRequestMaterialsService.put(
        `/api/mantainers/approval_matrix?password=${payload.password}`,
        payload
      )
      return resp
    } catch (error) {
      return error
    } finally {
      commit('SET_LOADING', false)
    }
  },
  async deleteEmployee({commit}, payload){
    commit('SET_LOADING', true)
    try {
      const resp = await ApiMsApproveRequestMaterialsService.delete(
        `/api/mantainers/delete_employees?password=${payload.password}&employee_id=${payload.employee_id}`
      )
      return resp
    } catch (error) {
      return error
    } finally {
      commit('SET_LOADING', false)
    }

  },
  async createEmployee({ commit }, payload) {
    commit('SET_LOADING', true)
    try {
      const resp = await ApiMsApproveRequestMaterialsService.post(
        `/api/mantainers/add_employees?password=${payload.password}`,
        {...payload, password: undefined}
      )
      if(resp.response){
        return resp.response
      }
      return resp
    } catch (error) {
      return error.response
    } finally {
      commit('SET_LOADING', false)
    }
  },
  async updateEmployee({ commit }, payload) {
    commit('SET_LOADING', true)
    try {
      const resp = await ApiMsApproveRequestMaterialsService.put(
        `/api/mantainers/update_employees?password=${payload.password}`,
        {...payload, password: undefined}
      )
      return resp
    } catch (error) {
      return error.response
    } finally {
      commit('SET_LOADING', false)
    }
  },
  async getEmailsNotificationForms({_}){
    try {
      const resp = (
        await deliveryRequestsService.get(`/api/mail/get_mails/`)
      ).data
      return resp
    } catch (error) {
      return error
    }
  },
  async updateEmailNotificationForm({_}, payload){
    const queryString = new URLSearchParams(payload).toString();
    try {
      const resp = (
        await deliveryRequestsService.post(`/api/mail/send_mail/?${queryString}`)
      )
      return resp
    } catch (error) {
      return error
    }
  },
  async deleteEmailNotificationForm({_}, payload){
    const queryString = new URLSearchParams(payload).toString();
    try {
      const resp = (
        await deliveryRequestsService.delete(`/api/mail/delete_mail/?${queryString}`)
      )
      return resp
    } catch (error) {
      return error
    }
  }

}
