import BaseService from "../../services/base_service"

const requestMaterialsService = new BaseService(
  process.env.VUE_APP_MS_REQUESTS_MATERIALS_URL,
  process.env.VUE_APP_MS_REQUESTS_MATERIALS_API_KEY
)

const deliveryRequestsService = new BaseService(
  process.env.VUE_APP_MS_DELIVERY_REQUESTS_URL,
  process.env.VUE_APP_MS_DELIVERY_REQUESTS_API_KEY,
)

import {getStockFromMaterial, getPriceFromMaterial} from '@/helpers/requestMaterial'
export const state = {
  loading: false,
  loadingMaterials: false,
  loadingSyncMaterials: false,
  allRequests: {
    total: 0,
    number_of_pages: 1,
    items: []
  },
  requestMaterials: {
    total_items: 0,
    total_pages: 1,
    items: []
  },
  projects: [],
  cecos: [],
  warehouses: [],
  availableStagesProjects: [],
  allMaterials: {
    total_items: 0,
    total_pages: 1,
    items: []
  },
  
}

export const getters = {
  loading: (state) => state.loading,
  loadingMaterials: (state) => state.loadingMaterials,
  loadingSyncMaterials: (state) => state.loadingSyncMaterials,
  allRequests: (state) => state.allRequests,
  requestMaterials: (state) => state.requestMaterials,
  allMaterials: (state) => state.allMaterials,
  availableStagesProjects: (state) => state.availableStagesProjects,
  projects: (state) => state.projects,
  cecos: (state) => state.cecos,
  warehouses: (state) => state.warehouses,
}

export const mutations = {
  SET_LOADING(state, payload) {
    state.loading = payload
  },
  SET_LOADING_MATERIALS(state, payload) {
    state.loadingMaterials = payload
  },
  SET_LOADING_SYNC_MATERIALS(state, payload){
    state.loadingSyncMaterials = payload
  },
  SET_ALL_REQUESTS(state, payload) {
    state.allRequests = payload
  },
  SET_REQUEST_MATERIALS(state, payload) {
    state.requestMaterials = payload
  },
  SET_ALL_MATERIALS(state, payload) {
    state.allMaterials = payload
    state.allMaterials.items = payload.items.map(item => {
      return {
        ...item,
        stock: getStockFromMaterial(item),
        price: getPriceFromMaterial(item)
      }
    })
  },
  SET_PROJECTS(state, payload){
    state.projects = payload
    state.projects.items = state.projects.items.map(item => {
      return {
        ...item,
        name_code: `${item.code} - ${item.name}`
      }
    })
  },
  SET_CECOS(state, payload){
    state.cecos = payload
    state.cecos.items = state.cecos.items.map(item => {
      return {
        ...item,
        name_code: `${item.sap_code} - ${item.name}`
      }
    })
  },
  SET_WAREHOUSES(state, payload){
    state.warehouses = payload
  },
  SET_AVAILABLE_STAGES_PROJECTS(state, payload) {
    state.availableStagesProjects = payload
  },
  CLEAR_PROJECTS(state, payload){
    state.projects = payload
  },
  CLEAR_MATERIALS(state, payload){
    state.allMaterials = payload
  }
}

export const actions = {
  async getAllRequests({ commit }, payload = {}) {
    try {
      const resp = (await deliveryRequestsService.get(
        `/api/dashboard/consolidated_requests`, payload)).data
      commit('SET_ALL_REQUESTS', resp)
      return resp
    } catch (error) {
      return error
    }
  },
  async getAllRequestsChartData({ commit }) {
    try {
      const resp = (await deliveryRequestsService.get(
        `/api/dashboard/consolidated_requests/request_by_month`)).data
      return resp
    } catch (error) {
      return error
    }
  },
  async getRequestMaterials({ commit }, payload = {}) {
    commit('SET_LOADING', true)
    try {
      const resp = (await requestMaterialsService.get(
        `/api/requests/get_requests`, payload)).data
      commit('SET_REQUEST_MATERIALS', resp)
      return resp
    } catch (error) {
      return error
    } finally {
      commit('SET_LOADING', false)
    }
  },
  async getAllMaterials({ commit }, payload = {}) {
    commit('SET_LOADING_MATERIALS', true)
    try {
      const resp = (await requestMaterialsService.get(
        `/api/requests/get_materials`, payload)).data
      commit('SET_ALL_MATERIALS', resp)
      return resp
    } catch (error) {
      return error
    } finally {
      commit('SET_LOADING_MATERIALS', false)
    }
  },
  async getAllMaterialsCostCenter({ commit }, payload = {}) {
    commit('SET_LOADING_MATERIALS', true)
    try {
      const resp = (await requestMaterialsService.get(
        `/api/cost_centers/get_materials`, payload)).data
      commit('SET_ALL_MATERIALS', resp)
      return resp
    } catch (error) {
      return error
    } finally {
      commit('SET_LOADING_MATERIALS', false)
    }
  },
  async syncAllMaterials({commit}){
    try {
      commit('SET_LOADING_SYNC_MATERIALS', true)
      const resp = await requestMaterialsService.post(
        `/api/maintenance/manual_material_maintenance`
      )
      return resp
    } catch (error) {
      return error.resp
    } finally {
      commit('SET_LOADING_SYNC_MATERIALS', false)
    }
  },
  async getMaterialsInfoList({_}, payload) {
    try {
      const resp = (await requestMaterialsService.get(
        `/api/requests/get_material_info_list`, payload)).data
      return resp
    } catch (error) {
      return error
    }
  },
  async createRequestMaterial({_}, payload) {
    try {
      const resp = await requestMaterialsService.post(
        '/api/requests/material_request', payload)
      return resp
    } catch (error) {
      return error.response
    }
  },
  async createRequestMaterialCostCenter({_}, payload) {
    try {
      const resp = await requestMaterialsService.post(
        '/api/cost_centers/material_request', payload)
      return resp
    } catch (error) {
      return error.response
    }
  },
  async getProjects({ commit }, payload) {
    try {
      const resp = (await requestMaterialsService.get(
        `/api/requests/get_projects`,
        payload
      )).data
      commit('SET_PROJECTS', resp)
      return resp
    } catch (error) {
      return error
    }
  },
  async getCecos({ commit }, payload) {
    try {
      const resp = (await requestMaterialsService.get(
        `/api/cost_centers/get_cost_centers`,
        payload
      )).data
      commit('SET_CECOS', resp)
      return resp
    } catch (error) {
      return error
    }
  },
  async getWarehouses({ commit }, payload) {
    try {
      const resp = (await requestMaterialsService.get(
        `/api/requests/get_warehouses`,
        payload
      )).data
      commit('SET_WAREHOUSES', resp)
      return resp
    } catch (error) {
      return error
    }
  },
  async updateReceptionFromMaterialRequestId({_}, payload) {
    try {
      const resp = await requestMaterialsService.put(
        `/api/requests/material_request_item?material_request_item_id=${payload.material_request_item_id}&field=${payload.field}&quantity=${payload.quantity}`, payload)
      return resp
    } catch (error) {
      return error.response
    }
  },
  async updateCommentFromMaterialRequestId({_}, payload) {
    try {
      const resp = await requestMaterialsService.put(
        `/api/requests/material_request_item_comment?material_request_item_id=${payload.material_request_item_id}&comment=${payload.comment}`, payload)
      return resp
    } catch (error) {
      return error.response
    }
  },
  async getStagesFromProject({_}, payload) {
    try {
      const resp = (await requestMaterialsService.get(
        `/api/requests/get_project_stages?project_code=${payload.project_code}`,
      )).data
      return resp
    } catch (error) {
      return error
    }
  },

  async getStagesAvailableFromProject({ commit }, payload) {
    try {
      const resp = (await requestMaterialsService.get(
        `/api/requests/get_available_project_stages?project_code=${payload.project_code}`,
      ))
      commit('SET_AVAILABLE_STAGES_PROJECTS', resp.data.stages)
      return resp
    } catch (error) {
      return error
    }
  },
  async downloadExcelRequestMaterials({ commit }, payload) {
    try {
      const resp = (
        await requestMaterialsService.get(
          `/api/requests/get_request_xlsx`,
          payload,
          'blob'
        )
      ).data
      return resp
    } catch (error) {
      return error
    }
  },
  clearAvailableStages({commit}, payload){
    commit('SET_AVAILABLE_STAGES_PROJECTS', payload)
  },
  clearProjects({commit}){
    commit('CLEAR_PROJECTS', [])
  },
  clearMaterials({commit}){
    commit('CLEAR_MATERIALS', {
      total_items: 0,
      total_pages: 1,
      items: []
    },)
  }

}
