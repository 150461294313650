import { userService } from "../../helpers/authservice/user.service";
import BaseService from "../../services/base_service";
import router from "../../router/index";
import RoleService from "@/helpers/authservice/role.service";
import JwtService from "@/helpers/authservice/jwt.service";

const user = JSON.parse(localStorage.getItem("user"));

const stateDefault = {
  usersInWhiteList: [],
  backgroundImagesMaintenance: [],
  maintenanceInfo: {
    title: null,
    description: null,
  },
  bannerAlertInfo: {
    id: null,
    active: false,
    text: ''
  }
}
export const state = user
  ? { status: { loggeduser: true }, user, ...stateDefault  }
  : { status: {}, user: null, ...stateDefault  };

const urlApi = process.env.VUE_APP_MS_PROJECTS_URL;

const requestMaintenanceService = new BaseService(
  urlApi,
  process.env.VUE_APP_MS_MATERIALS_API_KEY
);

export const actions = {
  // Logs in the user.
  // eslint-disable-next-line no-unused-vars
  login({ dispatch, commit }, { email, password }) {
    commit("loginRequest", { email });
    return new Promise((resolve, reject) => {
      userService.login(email, password).then(
        (user) => {
          commit("loginSuccess", user);
          resolve(user);
          if (user.debe_cambiar_clave) {
            router.push("/change-password");
          } else {
            router.push("/");
          }
        },
        (error) => {
          commit("loginFailure", error);
          // dispatch('notification/error', error, { root: true });
          reject(error);
        }
      );
    });
  },
  loginGoogle({ dispatch, commit }, datos) {
    commit("loginRequest", datos);
    return new Promise((resolve, reject) => {
      userService.loginGoogle(datos).then(
        (user) => {
          commit("loginSuccess", user);
          resolve(user);
          router.push("/");
        },
        (error) => {
          commit("loginFailure", error);
          // dispatch('notification/error', error, { root: true });
          reject(error);
        }
      );
    });
  },
  restaurarContrasena({ dispatch, commit }, datos) {
    // commit('loginRequest', datos);
    return new Promise((resolve, reject) => {
      userService.restaurarContrasena(datos).then(
        (user) => {
          // commit('loginSuccess', user);
          resolve(user);
          router.push("/");
        },
        (error) => {
          // commit('loginFailure', error);
          // dispatch('notification/error', error, { root: true });
          reject(error);
        }
      );
    });
  },
  cambiarContrasena({ dispatch, commit }, datos) {
    // commit('loginRequest', datos);
    return new Promise((resolve, reject) => {
      userService.cambiarContrasena(datos).then(
        (user) => {
          // commit('loginSuccess', user);
          resolve(user);
          // router.push('/');
        },
        (error) => {
          // commit('loginFailure', error);
          // dispatch('notification/error', error, { root: true });
          reject(error);
        }
      );
    });
  },
  verifyAuth({ dispatch, commit }) {
    if (JwtService.getToken()) {
      return new Promise((resolve, reject) => {
        userService.verifyAuth().then(
          (user) => {
            // commit('loginSuccess', user);
            if (!user || user.status == 401) {
              console.log("test");
              dispatch("logout");
              // router.push('login');
            }
          },
          (error) => {
            dispatch("logout");
          }
        );
      });
    } else {
      dispatch("logout");
    }
  },
  // Logout the user
  logout({ commit }) {
    userService.logout();
    commit("logout");
  },
  // register the user
  registeruser({ dispatch, commit }, user) {
    commit("registerRequest", user);
    userService.register(user).then(
      (user) => {
        commit("registerSuccess", user);
        dispatch("notification/success", "Registration successful", {
          root: true,
        });
        router.push("/login");
      },
      (error) => {
        commit("registerFailure", error);
        dispatch("notification/error", error, { root: true });
      }
    );
  },

  async getRequestMaterials({ commit }, payload = {}) {
    commit("SET_LOADING", true);
    try {
      const resp = (
        await requestMaterialsService.get(`/api/requests/get_requests`, payload)
      ).data;
      commit("SET_REQUEST_MATERIALS", resp);
      return resp;
    } catch (error) {
      return error;
    } finally {
      commit("SET_LOADING", false);
    }
  },

  async maintenance({commit}) {
    try {
      const resp = (await requestMaintenanceService.get(
        `/maintenance/maintenance_mode`
      )).data;
      return resp;
    } catch (error) {
      return error.response
    }
  },
  async updateMaintenanceMode({commit}){
    try {
      const resp = await requestMaintenanceService.put(
        `/maintenance/maintenance_mode`,
      );
      return resp;
    } catch (error) {
      return error.response
    }
  },
  async deleteUserWhitelist({commit}, payload){
    try {
      const resp = await requestMaintenanceService.delete(
        `/maintenance/whitelist/${payload}`,
      );
      commit('spliceDeletedUserWhitelist', payload)
      return resp;
    } catch (error) {
      return error.response
    }
  },
  async addUserWhitelist({commit}, payload){
    try {
      const resp = await requestMaintenanceService.post(
        `/maintenance/whitelist/${payload}`,
      );
      commit('addNewUserWhitelist', resp.data)
      return resp;
    } catch (error) {
      return error.response
    }
  },
  async getUsersInWhiteList({commit}) {
    try {
      const resp = (await requestMaintenanceService.get(
        `/maintenance/whitelist`
      )).data;
      commit('usersInWhiteList', resp)
      return resp;
    } catch (error) {
      return error.response
    }
  },
  async getBackgroundImagesMaintenance({commit}){
    try {
    const resp = (await requestMaintenanceService.get(
      `/maintenance/maintenance_image_list`
      )).data;
      commit('setBackgroundImagesMaintenance', resp)
      return resp;
    } catch (error) {
      return error.response
    }
  },
  async updateBackgroundImageMaintenance({commit}, payload){
    try {
      const formData = new FormData()
      formData.append('file', payload.file)
      const resp = await requestMaintenanceService.post(
        `/maintenance/upload_maintenance_image?user=${payload.user}`,
        formData
        );
        commit('setNewBackgroundImagesMaintenance', resp.data)
        return resp;
      } catch (error) {
        return error.response
      }
    },
    async getMaintenanceInfo({commit}){
      try {
      const resp = (await requestMaintenanceService.get(
        `/maintenance/maintenance_form`
        )).data;
        commit('setMaintenanceInfo', resp)
        return resp;
      } catch (error) {
        return error.response
      }
    },
    async updateMaintenanceInfo({commit}, payload){
      try {
        const resp = await requestMaintenanceService.post(
          `/maintenance/post_maintenance_form`,
          payload
        );
        commit('setMaintenanceInfo', resp.data)
        return resp;
      } catch (error) {
        return error.response
      }
    },
    async getBannerAlertInfo({commit}) {
      try {
        const resp = (await requestMaintenanceService.get(
          `/maintenance/maintenance_banner`
        )).data;
        commit('setBannerAlertInfo', resp)
        return resp;
      } catch (error) {
        return error.response
      }
    },
    async updateBannerAlertInfoStatus({commit}, payload){
      try {
        const resp = await requestMaintenanceService.put(
          `/maintenance/enable_maintenance_info/${payload.id}`,
        );
        commit('updateBannerAlertInfoStatus')
        return resp;
      } catch (error) {
        return error.response
      }
    },
    async updateOrCreateBannerAlertInfo({commit}, payload){
      try {
        let resp = null;
        if(payload.id){
          resp = await requestMaintenanceService.put(
            `/maintenance/maintenance_info/${payload.id}?info=${payload.info}`,
            payload
          );            
        }else{
          resp = await requestMaintenanceService.post(
            `/maintenance/maintenance_banner`,
            payload
          );
        }
        return resp;
      } catch (error) {
        return error.response
      }
    },
};

export const getters = {
  usersInWhiteList: (state) => state.usersInWhiteList,
  backgroundImagesMaintenance: (state) => state.backgroundImagesMaintenance,
  maintenanceInfo: (state) => state.maintenanceInfo,
  bannerAlertInfo: (state) => state.bannerAlertInfo
}

export const mutations = {
  loginRequest(state, user) {
    state.status = { loggingIn: true };
    state.user = user;
  },
  loginSuccess(state, user) {
    state.status = { loggeduser: true };
    state.user = user;
    JwtService.saveToken(state.user.token);
    RoleService.saveRole(state.user.codigo_rol);
  },
  loginFailure(state) {
    state.status = {};
    state.user = null;
  },
  logout(state) {
    state.status = {};
    state.user = null;
    RoleService.destroyRole();
    JwtService.destroyToken();
  },
  registerRequest(state) {
    state.status = { registering: true };
  },
  registerSuccess(state) {
    state.status = {};
  },
  registerFailure(state) {
    state.status = {};
  },
  usersInWhiteList(state, payload){
    state.usersInWhiteList = payload
  },
  spliceDeletedUserWhitelist(state, payload){
    state.usersInWhiteList = state.usersInWhiteList.filter(user => {
      return user.email != payload
    })
  },
  addNewUserWhitelist(state, payload){
    state.usersInWhiteList.push(payload)
  },
  setBackgroundImagesMaintenance(state, payload){
    state.backgroundImagesMaintenance = payload
  },
  setNewBackgroundImagesMaintenance(state, payload){
    state.backgroundImagesMaintenance.push(payload)
  },
  setMaintenanceInfo(state, payload) {
    state.maintenanceInfo = payload.length ? {
      title: payload.find(item => item.info_type == 'title')?.description,
      description: payload.find(item => item.info_type == 'description')?.description
    } : {
      title: '',
      description: ''
    }
  },
  setBannerAlertInfo(state, payload){
    state.bannerAlertInfo = {
      id: payload.id ? payload.id : null,
      active: payload.id ? payload.enabled : false,
      text: payload.id ? payload.description : ''
    }
  },
  updateBannerAlertInfoStatus(state){
    state.bannerAlertInfo.active = !state.bannerAlertInfo.active
  }
};
