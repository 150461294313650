import BaseService from "../../services/base_service"

const deliveryRequestsService = new BaseService(
  process.env.VUE_APP_MS_DELIVERY_REQUESTS_URL,
  process.env.VUE_APP_MS_DELIVERY_REQUESTS_API_KEY,
)

export const state = {
  loading: false,
  allRegions: []
}

export const getters = {
  loading: (state) => state.loading,
  allRegions: (state) => state.allRegions,
}

export const mutations = {
  SET_LOADING(state, payload) {
    state.loading = payload
  },
  SET_REGIONS(state, payload){
    state.allRegions = payload
  },
}

export const actions = {
  async getRegions({ commit }) {
    try {
      const resp = (await deliveryRequestsService.get(
        `/api/supplier_creation/regiones`)).data
      commit('SET_REGIONS', resp.regiones)
      return resp
    } catch (error) {
      return error
    }
  },
}
