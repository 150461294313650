import ApiMsApproveRequestMaterialsService from '@/services/ms_approve_request_materials'
import {getApproverFromEmail} from '@/helpers/requestMaterial'
export const state = {
  loading: false,
  materialsRequestsToApprove: [],
}

export const getters = {
  loading: (state) => state.loading,
  materialsRequestsToApprove: (state) => state.materialsRequestsToApprove,
}

export const mutations = {
  SET_LOADING(state, payload) {
    state.loading = payload
  },
  SET_MATERIALS_REQUESTS_TO_APPROVE(state, payload) {
    state.materialsRequestsToApprove = payload.map(item => {
      const {
        material_request_json,
        ...rest
      } = item
      return {
        ...rest,
        ...material_request_json,
        materials: Object.keys(material_request_json.materials).map(key => {
          return {
            code: key,
            quantity: material_request_json.materials[key],
            description: null,
            price: 0
          }
        }),
        default_request_material_json: material_request_json,
        possible_approvers: item.approvers_detail.length ? Object.entries(item.possible_approvers).map(posible => {
          return {
            selected: getApproverFromEmail(item.approvers_detail, posible[0]),
            approvers: posible[1].map(approver => {
              return getApproverFromEmail(item.approvers_detail, approver)
            })
          }
        }) : []
      }
    }).sort(function(a, b) {
      if ((a.sent_to_erp && !b.sent_to_erp) || (!a.rejected && b.rejected)) {
        return -1;
      } else if ((!a.sent_to_erp && b.sent_to_erp) || (a.rejected && !b.rejected)) {
        return 1;
      } else {
        return 0;
      }
    });
  },
  UPDATE_VALUE_REQUEST_APPROVAL(state, payload){
    let requestToApprove = state.materialsRequestsToApprove.find(item => item.id === payload.id)
    requestToApprove[payload.field] = payload.value
  },
  UPDATE_MATERIAL_REQUEST_APPROVAL(state, payload){
    let requestToApprove = state.materialsRequestsToApprove.find(item => item.id === payload.id)
    requestToApprove.materials.forEach(element => {
      if(element.code == payload.codeProduct){
        element[payload.field] = payload.value
      }
    });
  },
  UPDATE_MATERIALS_REQUEST_APPROVAL(state, payload){
    let requestToApprove = state.materialsRequestsToApprove.find(item => item.id === payload.id)
    requestToApprove.materials = [
      ...requestToApprove.materials,
      ...payload.materials
    ]
  },
  UPDATE_DATA_MATERIALS_CURRENT_REQUEST_APPROVE(state, payload){
    let requestToApprove = state.materialsRequestsToApprove.find(item => item.id === payload.id)
    requestToApprove.materials = requestToApprove.materials.map(item => {
      let material = payload.materials.find(material => material.code == item.code)
      return {
        ...item,
        price: material.material_prices.length ? material.material_prices[material.material_prices.length - 1].price : 0,
        description: material.name
      }
    }) 
  },
  UPDATE_CURRENT_APPROVER_REQUEST_APPROVAL(state, payload){
    let requestToApprove = state.materialsRequestsToApprove.find(item => item.id === payload.id)
    requestToApprove.possible_approvers.forEach(item => {
      if(item.selected.email === payload.original.email){
        item.original = getApproverFromEmail(
          requestToApprove.approvers_detail,
          item.selected.substitute ? item.selected.original : item.selected.email
        )
        item.selected = getApproverFromEmail(
          requestToApprove.approvers_detail,
          !payload.replacement.substitute ? payload.replacement.value : payload.replacement.original
        )
      }
    })
  },
  SPLICE_MATERIAL_REQUEST_APPROVAL(state, payload){
    let requestToApprove = state.materialsRequestsToApprove.find(item => item.id === payload.id)
    requestToApprove.materials = requestToApprove.materials.filter(element => {
      return element.code !== payload.codeProduct
    });
  }
}
export const actions = {
  async getMaterialsRequestsToApprove({ commit }, payload) {
    try {
      const resp = (
        await ApiMsApproveRequestMaterialsService.get(`/api/get_approvals`, payload)
      ).data
      commit('SET_MATERIALS_REQUESTS_TO_APPROVE', resp)
      return resp
    } catch (error) {
      return error
    }
  },
  async updateMaterialRequestApprove({ commit }, {params, payload}) {
    commit('SET_LOADING', true)
    try {
      const resp = await ApiMsApproveRequestMaterialsService.put(
        `/api/update_material_request?user=${params.user}&request_id=${params.request_id}&monto_nuevo=${params.monto_nuevo}`,
        payload
      )
      return resp
    } catch (error) {
      return error
    } finally {
      commit('SET_LOADING', false)
    }
  },
  async updateApprovalsRequestApprove({ commit }, payload) {
    commit('SET_LOADING', true)
    try {
      const resp = await ApiMsApproveRequestMaterialsService.put(
        `/api/update_material_request_approvers?user=${payload.user}&request_id=${payload.request_id}&original=${payload.original}&replacement=${payload.replacement}`,
        payload
      )
      return resp
    } catch (error) {
      return error
    } finally {
      commit('SET_LOADING', false)
    }
  },
  async approveOrRejectRequestApprove({ commit }, payload) {
    commit('SET_LOADING', true)
    try {
      const resp = await ApiMsApproveRequestMaterialsService.post(
        `/api/approve_material_request`,
        payload
      )
      return resp
    } catch (error) {
      return error
    } finally {
      commit('SET_LOADING', false)
    }
  },
  async cancelRequestApprove({ commit }, payload) {
    commit('SET_LOADING', true)
    try {
      const resp = await ApiMsApproveRequestMaterialsService.put(
        `/api/cancel_material_request?user=${payload.user}&request_id=${payload.request_id}`,
      )
      return resp
    } catch (error) {
      return error
    } finally {
      commit('SET_LOADING', false)
    }
  },
  cleanRequestsMaterialsToApprove({ commit }) {
    commit('SET_MATERIALS_REQUESTS_TO_APPROVE', [])
  },
  updateValueRequestApproval({commit}, payload){
    commit('UPDATE_VALUE_REQUEST_APPROVAL', payload)
  },
  updateMaterialRequestApproval({commit}, payload){
    commit('UPDATE_MATERIAL_REQUEST_APPROVAL', payload)
  },
  updateMaterialsRequestApproval({commit}, payload){
    commit('UPDATE_MATERIALS_REQUEST_APPROVAL', payload)
  },
  spliceMaterialRequestAproval({commit}, payload){
    commit('SPLICE_MATERIAL_REQUEST_APPROVAL', payload)
  },
  updateCurrentApproverRequestApproval({commit}, payload ){
    commit('UPDATE_CURRENT_APPROVER_REQUEST_APPROVAL', payload)
  },
  updateDataMaterialsCurrentRequestApprove({commit}, payload){
    commit('UPDATE_DATA_MATERIALS_CURRENT_REQUEST_APPROVE', payload)
  },
  async createRequestApproveMaterials({commit}, payload){
    commit('SET_LOADING', true)
    try {
      const resp = await ApiMsApproveRequestMaterialsService.post(`/api/material_request_approval`, payload)
      return resp
    } catch (error) {
      return error
    } finally {
      commit('SET_LOADING', false)
    }
  },
}
